/* #apppage .gradient {
    background: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4) 100%);
    background: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4) 100%);
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4) 100%);
} */

/* #videobackground .view video {
    z-index: -100;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: 1s opacity;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
} */

#apppage .view {
    background-image: url('../assets/aligngif.GIF');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    /* background: rgb(231, 225, 221); */
    min-height: 100vh;
}

body {
    font-family: 'Montserrat', sans-serif;
}
