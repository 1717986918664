/* BS NavBar CSS */
.navbar {
    min-height: 50px;
    font-size: 20px !important;
    font-family: 'Trirong', serif !important;
    font-weight: 500 !important;
    letter-spacing: 2px !important;
}

.marcellus {
    font-family: 'Marcellus', serif !important;
}

.trirong-thin {
    font-family: 'Trirong', serif !important;
    font-weight: 100 !important;
}

.trirong-med {
    font-family: 'Trirong', serif !important;
    font-weight: 400 !important;
}

.trirong-thick {
    font-family: 'Trirong', serif !important;
    font-weight: 600 !important;
}

.mirage {
    font-family: made-mirage-thin, serif;
}

.mirage-thick {
    font-family: made-mirage-thin, serif;
    font-weight: 500;
}

.hell {
    font-family: Helvetica, sans-serif !important;
}

/* NavBar Div */
/* .navDiv {
    min-height: 200px !important;
    background-color: #F1F6F7;
    z-index: 9999999999 !important;
    position: fixed !important;
} */


/* Background Colors */

.navcolor {
    background: rgb(241, 246, 247) !important;
}

.alignlightgrey {
    background: rgb(235, 231, 225) !important;
}

.aligngrey {
    background: rgb(231, 225, 221) !important;
}

.alignpoopbrown {
    background: rgb(129, 95, 81) !important;
}

.aligncream {
    background: rgb(235, 228, 212) !important;
}

.aligncreammed {
    background: rgb(218, 207, 201) !important;
}

.aligncreamdark {
    background: rgb(214, 193, 164) !important;
}


.alignbrown {
    background: rgb(129, 95, 81) !important;
}

.alignlightbrown {
    background-color: rgb(161, 144, 129) !important;
}

.alignbrown2 {
    background-color: rgb(151, 134, 119) !important;
}




/* Text Colors */

.aligncreamtext {
    color: rgb(239, 230, 215) !important;
}

.alignlightbluetext {
    color: rgb(241, 246, 247) !important;
}

.alignlightgreytext {
    color: rgb(228, 229, 231) !important;
}

.alignlightbrowntext {
    color: rgb(208, 198, 190) !important;
}

.aligndarkcreamtext {
    color: rgb(200, 181, 149) !important;
}

.aligndarkgreytext {
    color: rgb(80, 82, 80) !important;
}

.alignbrowntext {
    color: rgb(212, 167, 138) !important;
}

.spacing {
    line-height: 30px;
}


@media (max-width: 767.98px) {
    .phoneText {
        font-size: 13px !important;
    }
}

@media (max-width: 767.98px) {
    .peekaboo1 {
        visibility: hidden !important;
    }
}
@media (max-width: 767.98px) {
    .peekaboo2 {
        visibility: visible !important;
    }
}

.hrmargin {
    margin-bottom: 20px !important;
    margin-right: 200px !important;
    margin-left: 200px !important;
}