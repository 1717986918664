.spwidget-button-wrapper {
    text-align: center
}

.spwidget-button {
    display: inline-block;
    padding: 12px 24px;
    color: #fff !important;
    background: rgb(78, 78, 78);
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none
}

.spwidget-button:hover {
    background: black
}

.spwidget-button:active {
    color: rgba(255, 255, 255, .75) !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .15) inset
}